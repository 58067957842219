import React from "react";
import JsonData from "../../data/data.json";
import Content from "./Content";
import Contact from "../contact";

const Footer = () => {
  return (
    <div
      className="container-fluid footer"
      style={{ marginTop: 30, paddingTop: 40 }}
    >
      {window.innerWidth >= 768 ? (
        <div className="container">
          <div className="row" style={{ marginBottom: 40 }}>
            <div className="col-md-5">
              <hr />
              <div className="logoDiv">
                <img src="img/logo.png" alt="logo" />
                <h1>Advisory</h1>
              </div>
            </div>
            <div className="col-md-2">
              <hr />
              <Content
                title={JsonData?.footer?.company?.title}
                data={JsonData?.footer?.company?.data}
                navigation={JsonData?.footer?.company?.navigation}
              />
            </div>
            <div className="col-md-3">
              <hr />
              <Content
                title={JsonData?.footer?.services?.title}
                data={JsonData?.footer?.services?.data}
                navigation={JsonData?.footer?.services?.navigation}
              />
            </div>
            <div className="col-md-2">
              <hr />
              <Content
                title={JsonData?.footer?.support?.title}
                data={JsonData?.footer?.support?.data}
                navigation={JsonData?.footer?.support?.navigation}
              />
            </div>
          </div>
          <div className="row text-center">
            <h5><img src='img/icons/copyright.png' style={{width: 15, height: 15}}/>2010-2023 All Rights Reserved.</h5>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="logoDiv mobile-footer">
            <img src="img/logo.png" alt="logo" />
            <h2>Advisory</h2>
          </div>
          <Contact/>
        </div>
      )}
    </div>
  );
};

export default Footer;
