import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";

const AboutUS = () => {
  return (
    <Layout header={jsonData?.Header?.aboutus} mobileImage="about-mobile.png">
      <div className="container-fluid">
        <div className="container mobile-padding about">
          <div className="row content">
            <h3 className="headline headlineStart">{jsonData?.about?.title}</h3>
            <br />
            {/* <h5>{jsonData?.about?.paragraph}</h5> */}
            <h5>Welcome to <b>MB Advisory</b>, where your financial aspirations become our mission! With 17 years of industry expertise, our team of certified professionals is here to empower you with tailored financial consulting services designed for your unique journey.</h5>
            <br />
            <h3 className="headline headlineStart">
              {jsonData?.about?.perfectMatch}
            </h3>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            {/* <img src="img/images/home.png" /> */}
            <div className="gallery">
      <div className="main-image">
        <img src="img/images/about1.png" alt="Main house" />
      </div>
      <div className="side-images">
        <img src="img/images/about2.png" alt="House 1" />
        <img src="img/images/about3.png" alt="Dining area" />
        <img src="img/images/about4.png" alt="Decor" />
      </div>
    </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUS;
