import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Services } from "./components/services";
import  Gallery  from "./components/Gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Home from "./components/Home";
import MSME from "./components/MSME";
import RealEstate from "./components/RealEstate";
import LeaseRental from "./components/LeaseRental";
import ArcAdvisory from "./components/ArcAdvisory";
import BankGaurantee from "./components/BankGaurantee";
import InventoryFunding from "./components/InventoryFunding";
import ProjectLoan from "./components/ProjectLoan";
import Career from "./components/Career";
import AboutUS from "./components/AboutUS";
import ContactUs from "./components/ContactUs";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/MSME" element={<MSME />} />
          <Route path="/realEstate" element={<RealEstate />} />
          <Route path="/leaseRental" element={<LeaseRental />} />
          <Route path="/arc" element={<ArcAdvisory />} />
          <Route path="/projectLoan" element={<ProjectLoan />} />
          <Route path="/bankGuarantee" element={<BankGaurantee />} />
          <Route path="/inventoryFunding" element={<InventoryFunding />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </div>
    </Router>
    </div>
  );
};

export default App;
