import React from "react";
import Footer from "./Footer/Footer";

const Gallery = () => {
  const images = [
    "IMG_2153.JPG",
    "IMG_2154.JPG",
    "IMG_2151.JPG",
    "IMG_2147.JPG",
    "IMG_2149.JPG",
    "IMG_2145.JPG",
    "IMG_2137.JPG",
    "IMG_2138.JPG",
    "IMG_2139.JPG",
    "IMG_2152.JPG",
    "IMG_2141.JPG",
    "IMG_2142.JPG",
    // 'IMG_2143.JPG',
    "IMG_2144.JPG",
    // 'IMG_2146.JPG',
    // 'IMG_2150.JPG',
    "IMG_2140.JPG",
    "IMG_2136.JPG",
  ];
  return (
    <>
    <div className="container-fluid career" style={{ marginTop: 100 }}>
      <div className="container">
        {images?.map((img, index) => {
          if (index % 3 === 0) {
            return (
              <div className="row" key={`row-${index}`}>
                <div className="col-md-4" style={{ marginTop: 40 }}>
                  <img
                    src={`img/Gallery/${img}`}
                    style={{ width: "100%", height: 350 }}
                  />
                </div>
                {images?.slice(index + 1, index + 3).map((img, nextIndex) => (
                  <div className="col-md-4" style={{ marginTop: 40 }}>
                    <img
                      src={`img/Gallery/${img}`}
                      style={{ width: "100%", height: 350 }}
                    />
                  </div>
                ))}
              </div>
            );
          }
          return null;
        })}{" "}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Gallery;
