import React from "react";
import Layout from "./Layout";
import jsonData from "../data/data.json";
import ImageContent from "../intelcomponents/cards/ImageContentList";
import ImageBackground from "../intelcomponents/ImageBackground";
import IntelButton from "../intelcomponents/IntelButton";
import ListContent from "../intelcomponents/cards/ListContent";
import ImageContentIcon from "../intelcomponents/cards/ImageContentIcon";
import Treemap from "../intelcomponents/Treemap";

const MSME = () => {
  return (
    <Layout header={jsonData?.Header?.MSMELoan} mobileImage="msme-mobile.png">
      <div className="container-fluid">
        <div className="container mobile-padding MSME">
          <div className="row content">
            <h3
              className={`headline ${
                window.innerWidth <= 768 && "headlineStart"
              }`}
            >
              Eligibility Criteria
            </h3>
            <div className="row listSpacing">
              <ImageContent
                data={jsonData?.MSME}
                icon="correctList.png"
                image="eligibilty.png"
                displayImage="right"
              />
            </div>
          </div>
        </div>
      </div>
      <ImageBackground
        image="msme.png"
        imageBackground={window.innerWidth <= 768 ? true : true}
      >
        <div className="container mobile-padding MSME msme-loan">
          <div className="row content">
            <h3
              className={`headline ${
                window.innerWidth <= 768 && "headlineStart"
              }`}
            >
              Types of MSME Loans
            </h3>
            {jsonData?.MSMELoanTypes?.types?.map((loan, index) => {
              if (index % 3 === 0) {
                return (
                  <div className="row" key={`row-${index}`}>
                    <ListContent data={loan} />
                    {jsonData?.MSMELoanTypes?.types
                      ?.slice(index + 1, index + 3)
                      .map((nextLoan, nextIndex) => (
                        <ListContent
                          key={`loan-${index + nextIndex + 1}`}
                          data={nextLoan}
                        />
                      ))}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="row msme-loan-content">
            <h5>{jsonData?.MSMELoanTypes?.content}</h5>
          </div>
          <div className="row text-center button">
            <a href="/contact">
              <IntelButton
                name={jsonData?.MSMELoanTypes?.buttonText}
                button="light"
                startPosition={true}
              />
            </a>
          </div>
        </div>
      </ImageBackground>
      <div className="container-fluid">
        <div className="container MSME msme-benefits">
          <div
            className="row content"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ImageContentIcon
              image="moneyTree.png"
              data={jsonData?.MSMELoanTypes?.benefits}
              imageSide="left"
              title="Benefits of MSME Loans"
            />
          </div>
        </div>
      </div>
      {window.innerWidth >= 768 && (
        <div className="container-fluid">
          <div className="container">
            {/* <Treemap/> */}
            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src="img/images/required_documets.png" />
            </div>
          </div>
        </div>
      )}
      {window.innerWidth >= 768 ? <div className="container-fluid msme-schemes">
        <div className="container MSME ">
          <div
            className="row content"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ImageContentIcon
              image="msme-schemes.png"
              data={jsonData?.MSMELoanTypes?.schemes}
              imageSide="right"
              title="Government Schemes"
              marginStyle={{marginBottom: 40}}
            />
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              <h5 style={{ fontWeight: 200, marginBottom: 40, fontSize: 13 }}>
                {jsonData?.MSMELoanTypes?.schemesContent}
              </h5>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>: <ImageBackground
        image="govt-schemes.png"
      >
        <div className="container MSME msme-schemes">
          <div
            className="row content"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ImageContentIcon
              image="msme-schemes.png"
              data={jsonData?.MSMELoanTypes?.schemes}
              imageSide="right"
              title="Government Schemes"
              marginStyle={{marginBottom: 40}}
            />
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 text-center">
              <h5 style={{ fontWeight: 200, marginBottom: 40, fontSize: 13 }}>
                {jsonData?.MSMELoanTypes?.schemesContent}
              </h5>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        </ImageBackground>
    }
    </Layout>
  );
};

export default MSME;
