import React from "react";
import jsonData from "../data/data.json";
import IntelContent from "../intelcomponents/cards/IntelContent";
import Service from "../intelcomponents/cards/Service";
import Testimonial from "../intelcomponents/cards/Testimonial";
import IntelButton from "../intelcomponents/IntelButton";
import Layout from "./Layout";
import ImageBackground from "../intelcomponents/ImageBackground";
import FlexIconContent from "../intelcomponents/cards/FlexIconContent";

const Home = () => {
  return (
    <Layout header={jsonData?.Header?.Home} mobileImage="home.png" headerButton={true}>

      <div className="container mobile-padding">
      <div className="row content home">
            <h3>Welcome to MB Advisory,</h3>
            <h4>Where your financial aspirations become our mission! With 17 years of industry expertise, our team of certified professionals is here to empower you with tailored financial consulting services designed for your unique journey.</h4>
          </div>
      </div>
      {window.innerWidth >= 768 && (
        <div className="container mobile-padding">
          <div className="row content">
            {jsonData?.Content?.content?.map((data) => (
              <IntelContent key={data.id} data={data} />
            ))}
          </div>
        </div>
      )}

      {window.innerWidth >= 768 ? (
        <div className="container-fluid service content">
          <div className="container mobile-padding">
            <h3 className="headline">Our Services</h3>
            <hr />
            <hr className="secondHr" />
            {jsonData?.Services?.map((d, index) => {
              if (index % 4 === 0) {
                return (
                  <div className="row headlineSpace" key={`row-${index}`}>
                    <Service key={d.id} data={d} />
                    {jsonData?.Services?.slice(index + 1, index + 4).map(
                      (nextLoan) => (
                        <Service key={nextLoan.id} data={nextLoan} />
                      )
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid service content">
            <div className="container mobile-padding">
              <h3 className="headline headlineStart">Our Services</h3>
              <div className="row content">
                <ul>
                  {jsonData?.Services?.map((data) => (
                    <Service key={data.id} data={data} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid mission">
            <div className="container mobile-padding">
              <div className="row content">
                <h3 className="headline">
                  {jsonData?.Content?.content[0]?.title}
                </h3>
                <hr />
                <hr className="secondHr" />
                <h5>{jsonData?.Content?.content[0]?.paragraph}</h5>
              </div>
            </div>
          </div>
          <ImageBackground image="approach.png">
            <div className="container mobile-padding approach">
              <div className="row content">
                <h3 className="headline headlineStart">
                  {jsonData?.Content?.content[1]?.title}
                </h3>
                <h5>{jsonData?.Content?.content[1]?.paragraph}</h5>
              </div>
            </div>
          </ImageBackground>
        </>
      )}
      {window.innerWidth >= 768 ? (
          <div className="container-fluid content">
            <div className="container chooseUs mobile-padding">
              <h3 className="headline">Why Choose Us ?</h3>
              <div className="row headlineSpace">
                {jsonData?.chooseUs?.map((data) => (
                  <Service key={data.id} data={data} />
                ))}
              </div>
            </div>
          </div>
      ) : (
        <>
        <div className="container-fluid content">
        <div className="container chooseUs mobile-padding">
          <h3 className="headline headlineStart">Why Choose Us ?</h3>
          </div></div>
          <FlexIconContent data={jsonData?.chooseUs} flag="ChooseUs"  />
        </>
      )}
      <div className="container-fluid content testimonial">
        <div className="container">
          <div className="row">
            {jsonData?.testimonial?.map((testimonial) => (
              <Testimonial data={testimonial} />
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid content action">
        <div className="container">
          <div className={`row ${window.innerWidth >= 768 && "text-center"}`}>
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h3>{jsonData?.action?.title}</h3>
              <h5>{jsonData?.action?.text}</h5>
              <br />
              <a href="/contact"><IntelButton name="Write to Us" /></a>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
